import React from 'react';
import './App.css';
import {ReactComponent as Header} from './svg/header.svg'
import {ReactComponent as Deroule} from './svg/deroule.svg'
import coeurs from './svg/coeurs.svg'
import deco1 from './svg/deco1.svg'
import deco2 from './svg/deco2.svg'
import deco3 from './svg/deco3.svg'

function App() {
  const [nom, setNom] = React.useState<string>("");
  const [nbPersonnes, setNbPersonnes] = React.useState<string>("");
  const [dispo, setDispo] = React.useState<string | null>(null);
  const [message, setMessage] = React.useState<string>("");
  const [mailSent, setMailSent] = React.useState<boolean>(false);


  const handleChangeNom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNom(event.target.value);
  }

  const handleChangeNbPersonnes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNbPersonnes(event.target.value);
  }

  const handleChangeDispo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDispo(event.target.value);
  }

  const sendMail = async (event: any) => {
    event.preventDefault();
    if (mailSent) {
      setMessage("Participation déjà envoyée.");
      return;
    }
    if (!nom || nom === "" || !nbPersonnes || nbPersonnes === "" || !dispo) {
      setMessage("Formulaire incomplet")
      return;
    }
    const res = await fetch("https://us-central1-mariage-9f65b.cloudfunctions.net/sendMail", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        nom,
        nbPersonnes,
        peut: dispo === "p"
      })
    });
    console.log(res);
    setMailSent(true);
    setMessage("Participation envoyée !")
  }

  return (
    <div className="main">
      <Header/>
      <div className="main-title bold-title">Mariage de Lisa et Gaëtan</div>
      <div className="main-title">
        Le 24 Juin 2023
      </div>
      <div className="sub-title">
        Programme
      </div>
      <Deroule/>
      <div className="sub-title">
        Details
      </div>
      <div className="text">
        <p>
          Tous les invités sont les bienvenus pour la mairie, le vin d'honneur, la soirée et le brunch du lendemain, nous ne faisons pas de distinctions.
          Cependant la mairie ne dispose que de 40 places. Si nous dépassons ce nombre la priorité sera donnée à la famille et aux témoins.  La cérémonie à la mairie sera assez brève (30 minutes environ), nous referons une cérémonie moins officielle à
          la salle des fête pour tout le monde.
        </p>
        <p>
          Le thème du mariage sera plutôt champêtre, en toute simplicité. Il n'y a pas de dress-code, revêtez ce qu'il
          vous plaît.
        </p>
        <p>
          Marine, la soeur de Lisa, sera notre photographe pour la soirée, mais n’hésitez pas à faire vos meilleurs
          clichés et vidéos avec vos propres appareils/smartphones. Nous avons créé un dossier en ligne afin de pouvoir
          partager tous les fichiers facilement. <a href="https://1drv.ms/f/s!Asfe7HLtvcXngZoaeEZQP7I1mJ6mvA?e=aOHNoY">Suivez
          ce lien</a>, le mot de passe est « Girofar2023 ».
        </p>
      </div>
      <div className="sub-title">
        Invitation
      </div>
      <form className="rsvp">
        <label>Nom</label>
        <input type="text" onChange={handleChangeNom} value={nom}/>
        <label className="margin">Nombre de persones (enfants inclus)</label>
        <input type="number" min="0" max="10" onChange={handleChangeNbPersonnes} value={nbPersonnes}/>
        <fieldset className="margin">
          <legend>Participation :</legend>
          <div>
            <input type="radio" id="p" name="participation" value="p" onChange={handleChangeDispo}/>
            <label htmlFor="p">Pourra participer</label>
          </div>
          <div>
            <input type="radio" id="np" name="participation" value="np" onChange={handleChangeDispo}/>
            <label htmlFor="np">Ne pourra pas participer</label>
          </div>
        </fieldset>
        <button onClick={sendMail}>Envoyer</button>
        {message}
      </form>
      <div className="sub-title">
        Logement
      </div>
      <div className="text">Diverses options pour vous loger sur place:
        <ul>
          <li>
            La salle des fêtes dispose d'un carré d'herbe ou vous pourrez planter votre tente.<br/>
            Pour les finishers, la salle se tranformera en dortoir géant pour la nuit.<br/>
            Dans les deux cas, prévoyez ce qu'il vous faut pour dormir (tente, matelas, duvet, oreiller, etc...).
          </li>
          <li>Notre humble demeure (à 4 minutes en voiture) sera accessible. Nous avons quelques couchages sur place
            mais prévoyez de quoi dormir (matelas, duvet) dans la mesure du possible. Si le temps le permet notre jardin
            sera aussi à votre disposition pour planter votre tente. Nous n'organisons pas les navettes jusqu'à la
            maison.
          </li>
          <li>Pour les personnes disposants d'un camping-car, camion ou autre van aménagé il y a un petit parking plat et
            calme juste en dessous de la salle des fêtes.
          </li>
          <li>
            Quelques disponibilités en <a
            href="https://www.airbnb.fr/s/Saint~Jean~d'Avelanne--France/homes?adults=2&place_id=ChIJ5Z1tS90ci0cRcGu-5CqrCAQ&refinement_paths%5B%5D=%2Fhomes&checkin=2023-06-24&checkout=2023-06-25&tab_id=home_tab&query=Saint-Jean-d%27Avelanne%2C%20France&flexible_trip_lengths%5B%5D=one_week&price_filter_input_type=0&price_filter_num_nights=1&ne_lat=45.54438218481994&ne_lng=5.688869553961126&sw_lat=45.46371408308059&sw_lng=5.662727670970639&zoom=15&search_by_map=true&search_type=user_map_move"
            target="_blank" rel="noreferrer">AirBNB</a>, <a
            href="https://www.logishotels.com/fr/tarifs-et-disponibilites-chambre?id=3884&opt_chambre=2&DateIn=24%2F06%2F2023&DateOut=25%2F06%2F2023&nb_adultes=2&nb_enfants=0&nb_chambres=1"
            target="_blank" rel="noreferrer">hôtel</a> ou <a
            href="https://www.booking.com/hotel/fr/cote-jardin-le-pont-de-beauvoisin.fr.html?aid=301664&label=le-pont-de-beauvoisin-r2J3KuJizkyHpPhH4sh1DwS239960364394%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atikwd-48923373457%3Alp9108947%3Ali%3Adec%3Adm%3Appccp%3DUmFuZG9tSVYkc2RlIyh9YSNxgVPQVI7AMnn1KDvPMRs&dest_id=-1442811;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1677337709;srpvid=f7136a759882012c;type=total;ucfs=1&#hotelTmpl"
            target="_blank" rel="noreferrer">chambre d'hôte</a>.
          </li>
        </ul></div>
      <div className="sub-title">
        Playlist
      </div>
      <div className="text">Nous mettons à votre disposition deux playlists partagées, n'hésitez pas à ajouter vos
        meilleurs titres:
        <ul>
          <li><a
            href="https://open.spotify.com/playlist/0ivTMSQgE9AoFZahdoIvjo?si=68883f1b3a6f49d5&pt=26a28a26394d6b89e2cd3e7d0b5d6959">Playlist
            d'ambiance pour le vin d'honneur</a></li>
          <li><a
            href="https://open.spotify.com/playlist/2LjYbnMoG5IXCXxhJxuhdj?si=c1cb13975b7645c7&pt=afac981405a43b3fb0088259ff1dcf1b">Playlist
            pour danser</a></li>
        </ul>
      </div>
      <img src={coeurs} alt="coeurs" className="foot"/>
      <img src={deco1} alt="deco1" className="deco1 deco"/>
      <img src={deco2} alt="deco2" className="deco2 deco"/>
      <img src={deco3} alt="deco3" className="deco3 deco"/>
    </div>
  );
}

export default App;
